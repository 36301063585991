import React, { FC, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Badge from 'shared/Badge/Badge';
import NcImage from 'shared/NcImage/NcImage';

export interface CardCategoryBox1Props {
    className?: string;
    vehicle: any;
}
var interval: any = null;
const CardCategoryBox1: FC<CardCategoryBox1Props> = ({ className = '', vehicle }) => {
    const [time, setTime] = React.useState<any>(new Date(vehicle.time).valueOf() - Date.now());

    useEffect(() => {
        // count down
        if (vehicle.time) {
            console.log(vehicle._id);

            interval = setInterval(() => {
                setTime(new Date(vehicle.time).valueOf() - Date.now());
            }, 1000);
            return () => {
                clearInterval(interval);
                console.log('clear interval');
            };
        }
    }, []);

    return (
        <Link
            to={`/listing/${vehicle._id}`}
            className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
            data-nc-id="CardCategoryBox1"
        >
            {/* <Badge
        className="absolute right-2 top-2"
        color="gray"
        name={convertNumbThousand(0)}
      /> */}

            {
                // green available
                <Badge
                    className="absolute right-2 top-2"
                    color={vehicle.time ? 'red' : 'green'}
                    name={
                        vehicle.time ? (
                            <>
                                {
                                    // format time to days, hours and minutes and add 2 hrs
                                    (Math.floor(time / (1000 * 60 * 60 * 24)) > 0 ? Math.floor(time / (1000 * 60 * 60 * 24)) + 'd ' : '') +
                                        (Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60) + 2) + 'h ') +
                                        (Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)) + 'm ') +
                                        (Math.floor((time % (1000 * 60)) / 1000) + 's')
                                }
                            </>
                        ) : (
                            vehicle.minDuration == 1 ? 'Available Hourly' : 'Available' 
                        )
                    }
                />
            }
            {/* {
                // green available
                vehicle.minDuration == 1 && <Badge className="absolute right-24 top-2" color={'green'} name={'Available Hourly'} />
            } */}
            <div className="relative flex-shrink-0 w-52 h-36 rounded-lg overflow-hidden">
                <NcImage src={vehicle.image} containerClassName="absolute inset-0 object-cover" style={{ objectFit: 'cover' }} />
            </div>
            <div className="ml-4 flex-grow overflow-hidden mt-4">
                <h2 className=" text-lg font-medium">
                    <span className="line-clamp-1">{vehicle.name}</span>
                </h2>
                <span className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}>
                    {
                        // rupee symbol
                        '₹ ' + vehicle?.pricePerHour + ' / hour'
                    }
                </span>
                <span className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}>
                    {
                        // rupee symbol
                        '📍' + vehicle?.location + ''
                    }
                </span>
            </div>
        </Link>
    );
};

export default React.memo(CardCategoryBox1);
