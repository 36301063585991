import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";

const FAQ: React.FC = () => (
    <div className="nc-PageOffers">
        <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
            {/* HEADER */}
            <header className="text-center max-w-2xl mx-auto space-y-2">
                <div className="flex justify-center">
                   <h1>FAQs</h1>
                </div>
                <iframe src="https://docs.google.com/document/d/e/2PACX-1vQkE5GboInznejQ9YIQ2okRcle9_aUrfus3PhXgRGbGqnvdGRseTnj02AR-IFhFvzyF6GAqTxGvUjsK/pub?embedded=true" className="rounded-lg w-screen h-screen"></iframe>
                <div className="pt-8">
                    <ButtonPrimary href="/">Return Home Page</ButtonPrimary>
                </div>
            </header>
        </div>
    </div>
);

export default FAQ;
