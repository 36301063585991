import React, { useEffect, useState } from 'react';
import LocationInput from './LocationInput';
import { FocusedInputShape } from 'react-dates';
import RentalCarDatesRangeInput from './RentalCarDatesRangeInput';
import { FC } from 'react';
import moment, { Moment } from 'moment';
import { useDateContext } from 'contexts/DateContext';

export interface DateRage {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
}

export interface TimeRage {
    startTime: string;
    endTime: string;
}

export interface RentalCarSearchFormProps {
    haveDefaultValue?: boolean;
}

const getDate = (startDate: Moment, startTime: string): number => {
    // if(startDate==null) return;

    var time = startTime.split(' ');
    var hour = time[0].split(':')[0];
    var min = time[0].split(':')[1];
    var ampm = time[1];
    if (ampm == 'PM') {
        hour = (parseInt(hour) + 12).toString();
    }

    var date = startDate?.toDate().setHours(parseInt(hour), parseInt(min), 0, 0);

    return date;
};

const RentalCarSearchForm: FC<RentalCarSearchFormProps> = ({ haveDefaultValue }) => {
    // DEFAULT DATA FOR ARCHIVE PAGE
    const defaultPickUpInputValue = 'Hyderabad, Telangana, India';
    const defaultDropOffInputValue = 'Paris, France';

    const {
        dateRangeValue,
        setDateRangeValue,
        timeRangeValue,
        setTimeRangeValue,
        bookingLocation: pickUpInputValue,
        setBookingLocation: setPickUpInputValue,
    } = useDateContext();
    // USE STATE
    // const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    //   startDate: null,
    //   endDate: null,
    // });
    // const [timeRangeValue, setTimeRangeValue] = useState<TimeRage>({
    //   startTime: "10:00 AM",
    //   endTime: "10:00 AM",
    // });
    // const [pickUpInputValue, setPickUpInputValue] = useState('Hyderabad, India');
    const [dropOffInputValue, setDropOffInputValue] = useState('');
    const [fieldFocused, setFieldFocused] = useState<FocusedInputShape | 'dropOffInput' | null>(null);
    const [dropOffLocationType, setDropOffLocationType] = useState<'same' | 'different'>('same');

    // USER EFFECT
    useEffect(() => {
        if (haveDefaultValue) {
            setDateRangeValue({
                startDate: moment(),
                endDate: moment().add(4, 'days'),
            });

            setPickUpInputValue(defaultPickUpInputValue);
            setDropOffInputValue(defaultDropOffInputValue);
        }
    }, []);
    //

    const renderRadioBtn = () => {
        return (
            <div className=" py-5 [ nc-hero-field-padding ] flex items-center flex-wrap flex-row border-b border-neutral-100 dark:border-neutral-700">
                <div
                    className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
                        dropOffLocationType === 'same' ? 'bg-black text-white shadow-black/10 shadow-lg' : 'border border-neutral-300 dark:border-neutral-700'
                    }`}
                    onClick={(e) => setDropOffLocationType('same')}
                >
                    Same drop off
                </div>
                <div
                    className={`py-1.5 px-4 flex items-center rounded-full font-medium text-xs cursor-pointer mr-2 my-1 sm:mr-4 ${
                        dropOffLocationType === 'different' ? 'bg-black text-white shadow-black/10 shadow-lg' : 'border border-neutral-300 dark:border-neutral-700'
                    }`}
                    onClick={(e) => setDropOffLocationType('different')}
                >
                    Different drop off
                </div>
            </div>
        );
    };

    const renderForm = () => {
        return (
            <div className="w-full">
                <form
                    className={
                        'w-full relative mt-8 rounded-[40px] xl:rounded-[49px] shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800'
                        // (openPicker ? ' !rounded-bl-none' : '')
                    }
                >
                    {/* {renderRadioBtn()} */}
                    <div className="flex ">
                        <div className={'flex-1 relative flex flex-row'}>
                            <div className="w-full" /*onClick={() => setOpenPicker(!openPicker)}*/>
                                <LocationInput
                                    disabled
                                    defaultValue={pickUpInputValue|| 'LB Nagar, Meerpet, Hyderabad'}
                                    onChange={(e) => setPickUpInputValue(e)}
                                    onInputDone={() => setFieldFocused(dropOffLocationType === 'different' ? 'dropOffInput' : 'startDate')}
                                    // placeHolder="City or Airport"
                                    desc="Pick up location"
                                    className={'flex-1 w-full'}
                                />
                            </div>

                            {dropOffLocationType === 'different' && (
                                <LocationInput
                                    defaultValue={dropOffInputValue}
                                    onChange={(e) => setDropOffInputValue(e)}
                                    onInputDone={() => setFieldFocused('startDate')}
                                    // placeHolder="City or Airport"
                                    desc="Drop off location"
                                    autoFocus={fieldFocused === 'dropOffInput'}
                                    className="flex-1"
                                />
                            )}
                        </div>
                        <RentalCarDatesRangeInput
                            defaultDateValue={dateRangeValue}
                            defaultTimeValue={timeRangeValue}
                            defaultFocus={fieldFocused === 'dropOffInput' ? null : fieldFocused}
                            onFocusChange={(focus) => setFieldFocused(focus)}
                            onChange={(data) => {
                                setDateRangeValue(data.stateDate);
                                setTimeRangeValue(data.stateTimeRage);
                            }}
                            className={` ${dropOffLocationType === 'different' ? 'flex-1' : 'flex-[1.3]'}`}
                            buttonSubmitHref={`/listing?start=${getDate(dateRangeValue.startDate, timeRangeValue.startTime)}&end=${getDate(
                                dateRangeValue.endDate,
                                timeRangeValue.endTime
                            )}&location=${pickUpInputValue}`}
                        />
                    </div>
                </form>
            </div>
        );
    };

    return renderForm();
};

export default RentalCarSearchForm;
