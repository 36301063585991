import moment from 'moment';
import React, { useEffect, useState } from 'react';
import axios from '../services/api';

const DateContext = React.createContext({
    bookingLocation: '',
    setBookingLocation: (value: string) => {},
    availableLocations: [],
    setAvailableLocations: (value: any) => {},
});
export interface DateRage {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
}
export interface TimeRage {
    startTime: string;
    endTime: string;
}

export const defaultStartDate = () => {
    // use moment to get current time
    // if current time is greater than 8:00 PM then set start date to next day 8:00 AM
    // else set start date to current time + 1 hour
    let currentDate = new Date();
    currentDate.setMinutes(0, 0, 0);
    
    const currentHour = currentDate.getHours();
    
    if (currentHour >= 20 || currentHour < 8) {
        if (currentHour >= 20) {
            // Create a new date for tomorrow
            const tomorrow = new Date(currentDate);
            tomorrow.setDate(currentDate.getDate() + 1);
            tomorrow.setHours(8, 0, 0, 0);
            return tomorrow.getTime();
        } else {
            // Early morning, set to 8 AM today
            currentDate.setHours(8, 0, 0, 0);
            return currentDate.getTime();
        }
    } else {
        // Normal hours, set to current hour + 1
        currentDate.setHours(currentHour + 1, 0, 0, 0);
        return currentDate.getTime();
    }
};

export const defaultEndDate = () => {
    let currentDate = new Date();
    currentDate.setMinutes(0, 0, 0);
    
    const currentHour = currentDate.getHours();
    
    // Create a date for the end time (next day by default)
    const endDate = new Date(currentDate);
    endDate.setDate(currentDate.getDate() + 1);
    
    // If current time is 8 PM or later, set end date to next day at 8 PM
    if (currentHour >= 20) {
        endDate.setHours(20, 0, 0, 0);
        return endDate.getTime();
    }
    // If current time is before 8 AM, set end date to today at 8 PM
    else if (currentHour < 8) {
        endDate.setDate(currentDate.getDate()); // Same day
        endDate.setHours(20, 0, 0, 0);
        return endDate.getTime();
    }
    // Normal hours, set end date to next day at same hour
    else {
        endDate.setHours(currentHour, 0, 0, 0);
        return endDate.getTime();
    }
};

export const DateProvider = ({ children }: any) => {
    const [dateRangeValue, setDateRangeValueRaw] = useState<DateRage>({
        startDate: moment(defaultStartDate()),
        endDate: moment(defaultEndDate()),
    });
    const [timeRangeValue, setTimeRangeValueRaw] = useState<TimeRage>({
        startTime: '10:00 AM',
        endTime: '10:00 AM',
    });
    const [bookingLocation, setBookingLocation] = useState<string>('');
    const [availableLocations, setAvailableLocations] = useState<any>([]);

    const setDateRangeValue = (value: DateRage) => {
        // if value.startDate is less than current time then message.error("Start date must be greater than current time")
        if (value.startDate && value.endDate) {
            if (value.startDate.isBefore(moment())) {
                // message.error('Start date must be greater than current time');
                return;
            }
            if (value.endDate.isBefore(value.startDate)) {
                // message.error('End date must be greater than start date');
                return;
            }
            if (value.startDate.isAfter(value.endDate)) {
                // if start date time is 8:00 PM then set end date to next day 8:00 PM
                // else set end date to start date time + 1 hour
                if (value.startDate.hour() === 20 && value.startDate.minute() === 0) {
                    value.endDate = moment(value.endDate).set({ hour: 20, minute: 0, second: 0 });
                } else {
                    value.endDate = moment(value.startDate).set({ hour: value.startDate.hour() + 1, minute: 0, second: 0 });
                }
            }

            if (!value.startDate.isSame(dateRangeValue.startDate, 'day')) {
                setTimeRangeValueRaw({
                    startTime: timeRangeValue.startTime,
                    endTime: timeRangeValue.endTime,
                });
            }
            if (!value.endDate.isSame(dateRangeValue.endDate, 'day')) {
                setTimeRangeValueRaw({
                    endTime: '8:00 PM',
                    startTime: timeRangeValue.startTime,
                });
                value.endDate = moment(value.endDate).set({
                    hour: 20,
                    minute: 0,
                    second: 0,
                });
            }

            setDateRangeValueRaw(value);
        } else {
            // if value.startDate is moment and value.endDate is null then set value.endDate to value.startDate + 1 hour
            if (value.startDate && !value.endDate) {
                setDateRangeValueRaw(value);
            }
        }
    };
    
    const setTimeRangeValue = (value: TimeRage) => {
        // if dateRangeValue.startDate === current date then check time
        // if value.startTime is less than current time then message.error("Start time must be greater than current time")
        if (value.startTime && value.endTime && dateRangeValue.startDate && dateRangeValue.endDate) {
            const startDateWithNewTime = moment(dateRangeValue.startDate).set({
                hour: moment(value.startTime, 'h:mm A').hour(),
                minute: moment(value.startTime, 'h:mm A').minute(),
                second: 0,
            });

            if (startDateWithNewTime.isBefore(moment())) {
                // get current time in h:mm A and round to next hour and 0 minutes and 0 seconds
                const currentTime = moment().add(1, 'hour').set({ minute: 0, second: 0 }).format('h:mm A');

                setTimeRangeValueRaw({
                    startTime: currentTime,
                    endTime: value.endTime,
                });
                return;
            }

            if (dateRangeValue.startDate.isSame(dateRangeValue.endDate, 'day')) {
                const startTimeMoment = moment(value.startTime, 'h:mm A');
                const endTimeMoment = moment(value.endTime, 'h:mm A');
                
                if (startTimeMoment.isSameOrAfter(endTimeMoment)) {
                    let endDateTime;
                    
                    if (startTimeMoment.hour() === 20 && startTimeMoment.minute() === 0) {
                        endDateTime = moment(dateRangeValue.endDate).set({
                            hour: 20,
                            minute: 0,
                            second: 0,
                        });
                    } else {
                        endDateTime = moment(dateRangeValue.startDate).set({
                            hour: startTimeMoment.hour() + 1,
                            minute: 0,
                            second: 0,
                        });
                    }
                    
                    setTimeRangeValueRaw({
                        startTime: value.startTime,
                        endTime: endDateTime.format('h:mm A'),
                    });
                    return;
                }
            }
            
            // Same day and different times logic
            if (dateRangeValue.startDate.isSame(dateRangeValue.endDate, 'day')) {
                const startTimeMoment = moment(value.startTime, 'h:mm A');
                const endTimeMoment = moment(value.endTime, 'h:mm A');
                
                if (startTimeMoment.isAfter(endTimeMoment)) {
                    let endDateTime;
                    
                    if (startTimeMoment.hour() === 20 && startTimeMoment.minute() === 0) {
                        endDateTime = moment(dateRangeValue.endDate).set({
                            hour: 20,
                            minute: 0,
                            second: 0,
                        });
                    } else {
                        endDateTime = moment(dateRangeValue.startDate).set({
                            hour: startTimeMoment.hour() + 1,
                            minute: 0,
                            second: 0,
                        });
                    }
                    
                    setTimeRangeValueRaw({
                        startTime: value.startTime,
                        endTime: endDateTime.format('h:mm A'),
                    });
                    return;
                }
            }
        }

        setTimeRangeValueRaw(value);
    };
    
    const fetchAvailableLocations = async () => {
        try {
            const availableLocations = await axios.get('/available-locations').then((res) => res.data.data);
            setAvailableLocations(availableLocations);
        } catch (error) {
            console.error('Error fetching available locations:', error);
        }
    };
    
    useEffect(() => {
        fetchAvailableLocations();
        const searchParams = new URLSearchParams(window.location.search);
        const st = parseInt(searchParams.get('start') || defaultStartDate().toString());
        const en = parseInt(searchParams.get('end') || defaultEndDate().toString());
        
        const startDate = moment(st);
        const endDate = moment(en);
        
        if (startDate.isValid() && endDate.isValid()) {
            setDateRangeValue({
                startDate,
                endDate,
            });
        }
        
        const startTime = moment(st).format('h:mm A');
        const endTime = moment(en).format('h:mm A');
        
        if (startTime && endTime) {
            setTimeRangeValue({
                startTime,
                endTime,
            });
        }
    }, []);

    const value = {
        dateRangeValue,
        setDateRangeValue,
        timeRangeValue,
        setTimeRangeValue,
        bookingLocation,
        setBookingLocation,
        availableLocations,
        setAvailableLocations,
    };

    return <DateContext.Provider value={value}>{children}</DateContext.Provider>;
};

export const useDateContext = () => {
    var { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue, bookingLocation, setBookingLocation, availableLocations, setAvailableLocations }: any =
        React.useContext(DateContext);
    return {
        dateRangeValue,
        setDateRangeValue,
        timeRangeValue,
        setTimeRangeValue,
        bookingLocation,
        setBookingLocation,
        availableLocations,
        setAvailableLocations,
    };
};