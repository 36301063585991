import { initializeApp } from 'firebase/app';
import { GoogleAuthProvider } from "firebase/auth";
import { getAnalytics,logEvent  } from "firebase/analytics";


export const firebaseConfig = {
    apiKey: 'AIzaSyCAdPvedYEwKmHFzxshmDJv9c_BGx6XrAE',
    authDomain: 'startrides-com.firebaseapp.com',
    projectId: 'startrides-com',
    storageBucket: 'startrides-com.appspot.com',
    messagingSenderId: '909047405653',
    appId: '1:909047405653:web:fcc5cca47308c16b4148dc',
    measurementId: 'G-DSPJH8RYJ5',
};
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
logEvent(analytics, 'notification_received');
