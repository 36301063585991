// context for booking
import React, { createContext, useState } from "react";
import { getAuth, signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { getFirestore, collection, getDocs, doc, setDoc, getDoc, updateDoc, arrayUnion, arrayRemove } from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSearchDate } from "../containers/ListingCarPage/ListingCarPage";
import { useDateContext } from "./DateContext";

const auth = getAuth();

export const BookingContext = createContext({});
export const BookingProvider = ({ children }: any) => {
    var { dateRangeValue, setDateRangeValue, timeRangeValue, setTimeRangeValue }: any = useDateContext();

    const [booking, setBooking] = useState<any>({
        outStation: false,
    });


    return <BookingContext.Provider value={{ booking, setBooking }}>{children}</BookingContext.Provider>;

}

export const useBookingData = () => {
    const { booking, setBooking }: any = React.useContext(BookingContext);
    return { booking, setBooking };
}