import React from 'react';
import { BookingProvider } from 'contexts/BookingContext';
import { DateProvider } from 'contexts/DateContext';
import { UserProvider } from 'contexts/UserContext';
import MyRouter from 'routers/index';
import { PostHogProvider } from 'posthog-js/react';

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
};

function App() {
    return (
        <div className="min-h-full bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
            <PostHogProvider apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY!} options={options}>
                <UserProvider>
                    <BookingProvider>
                        <DateProvider>
                            <MyRouter />
                        </DateProvider>
                    </BookingProvider>
                </UserProvider>
            </PostHogProvider>
        </div>
    );
}

export default App;