import { message } from "antd";
import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut, User } from "firebase/auth";
import { jwtDecode } from "jwt-decode";
import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "../services/api";

// Define types for your user context
export interface UserContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  verified: boolean;
  loginWithGoogle: () => Promise<void>;
  logout: () => Promise<void>;
  verifyUser: () => Promise<boolean>;
  checkUserAuthentication: () => Promise<void>;
  getUserEmail: () => string | null;
}

// Create the context with default values
export const UserContext = createContext<UserContextType>({
  user: null,
  loading: true,
  error: null,
  verified: false,
  loginWithGoogle: async () => { },
  logout: async () => { },
  verifyUser: async () => false,
  checkUserAuthentication: async () => { },
  getUserEmail: () => null
});

// Create a hook for easy context usage
export const useUser = () => useContext(UserContext);

// Initialize Firebase Auth
const auth = getAuth();
const googleProvider = new GoogleAuthProvider();

export const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [verified, setVerified] = useState(false);
  // Get user email from token
  const getUserEmail = (): string | null => {
    const token = sessionStorage.getItem("authToken");
    if (!token) return null;

    try {
      const decodedToken: any = jwtDecode(token);
      return decodedToken?.email ||
        decodedToken?.payload?.email ||
        decodedToken?.claims?.email || null;
    } catch (error) {
      console.error("Error decoding token:", error);
      return null;
    }
  };

  // Check if user is authenticated with backend
  const checkUserAuthentication = async (): Promise<void> => {
    const token = sessionStorage.getItem("authToken");
    if (!token || !user) return;

    try {
      const email = user.email;
      if (!email) return;

      const response = await axios.post("/checkUser",
        { email },
        { headers: { Authorization: token } }
      );

      if (!response.data.verified) {
        // If not verified, redirect will happen in the component
        console.log("User not verified with backend");
      }
    } catch (error) {
      console.error("Authentication check failed:", error);
      sessionStorage.removeItem("authToken");
    }
  };

  // Login with Google
  const loginWithGoogle = async (): Promise<void> => {
    setError(null);

    try {
      const result = await signInWithPopup(auth, googleProvider);
      const loggedInUser = result.user;
      const email = loggedInUser.email;

      if (!email) {
        throw new Error("Failed to retrieve email");
      }

      // Get Firebase ID token
      const idToken = await loggedInUser.getIdToken(true);
      sessionStorage.setItem("authToken", idToken);  
      window.location.href = "/";

      // if (response.data.verified) {
      //   message.success("Login successful!");
      //   window.location.href = "/";  // Redirect to home
      // } else {
      //   message.info("User not verified. Redirecting to verification page.");
      //   window.location.href = "/verify"; // Redirect to verification page
      // }
    } catch (error: any) {
      console.error("Login error:", error);
      setError(error.message || "Login failed");
      message.error(error.message || "Login Failed");
    }
  };

  // Verify user
  const verifyUser = async (): Promise<boolean> => {
    setError(null);
    const email = getUserEmail();

    if (!email) {
      message.error("User email not found. Please login again.");
      return false;
    }

    try {
      const token = sessionStorage.getItem("authToken");

      const headers: Record<string, string> = {
        "Content-Type": "application/json"
      };

      if (token) {
        headers["Authorization"] = token;
      }

      const response = await axios.post(
        "/verifyUser",
        { email },
        { headers }
      );

      if (response.data.status) {
        message.success("Account verified successfully!");
        return true;
      } else {
        setError(response.data.message || "Verification failed");
        message.error(response.data.message || "Verification failed");
        return false;
      }
    } catch (error: any) {
      console.error("Verification error:", error);
      setError(error.message || "An error occurred while verifying the account");
      message.error("An error occurred while verifying the account");
      return false;
    }
  };

  // Logout function
  const logout = async (): Promise<void> => {
    try {
      await signOut(auth);
      sessionStorage.removeItem("authToken");
      message.success("Logged out successfully");
      window.location.href = "/login";
    } catch (error) {
      console.error("Logout error:", error);
      message.error("Failed to logout");
    }
  };

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);

      if (currentUser) {
        // If user is logged in, verify with backend
        checkUserAuthentication();
      }
    });

    return () => unsubscribe();
  }, []);

  // Context value
  const value: UserContextType = {
    user,
    loading,
    error,
    verified,
    loginWithGoogle,
    logout,
    verifyUser,
    checkUserAuthentication,
    getUserEmail
  };

  return (
    <UserContext.Provider value={value}>
      {!loading && children}
    </UserContext.Provider>
  );
};