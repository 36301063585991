import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import googleSvg from "images/Google.svg";
import { useUser } from "contexts/UserContext";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  const history = useHistory();
  const { user, loading, loginWithGoogle } = useUser();
  const [loginInProgress, setLoginInProgress] = useState(false); // Prevent multiple logins

  // Redirect when user is authenticated and token is available
  useEffect(() => {
    const token = sessionStorage.getItem("authToken");
    if (user && token) {
      history.push("/");
    }
  }, [user, history]);

  const handleLogin = async () => {
    if (loading || loginInProgress) return; // Prevent duplicate calls

    setLoginInProgress(true);
    try {
      await loginWithGoogle();
    } catch (error) {
      console.error("Login failed:", error);
    }
    setLoginInProgress(false);
  };

  const loginSocials = [
    {
      name: "Continue with Google",
      icon: googleSvg,
      onClick: handleLogin,
    },
  ];

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Booking React Template</title>
      </Helmet>
      <div className="container mb-24 lg:mb-32 h-full">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Login
        </h2>
        <div className="max-w-md mx-auto space-y-6">
          <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <button
                key={index}
                onClick={item.onClick}
                disabled={loading || loginInProgress}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img className="flex-shrink-0" src={item.icon} alt={item.name} />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {loading || loginInProgress ? "Loading..." : item.name}
                </h3>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
