import React, { useState, useEffect } from "react";
import { message } from "antd";
import axios from "../../services/api";
import { useHistory, useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

interface User {
    _id?: string;
    uid?: string;
    displayName: string;
    email: string;
    photoURL?: string;
    phone: string;
    aadhar?: string;
    role?: string;
    status?: string;
    verified?: boolean;
    createdAt?: string;
    updatedAt?: string;
}

const UserVerification: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState("");
    const [userObject, setUserObject] = useState<User>({ displayName: "", email: "", phone: "", aadhar: "" });
    const [aadhar, setAadhar] = useState("");
    const [otp, setOtp] = useState("");
    const [otpSent, setOtpSent] = useState(false);
    const [aadharVerified, setAadharVerified] = useState(false);
    const [refid, setRefid] = useState("");

    const history = useHistory();
    const location = useLocation();

    const getEmailFromQuery = (): string | null => {
        const params = new URLSearchParams(location.search);
        return params.get('email');
    };

    useEffect(() => {
        const token = sessionStorage.getItem("authToken");
        let email: string | null = null;

        if (token) {
            try {
                const decodedToken: any = jwtDecode(token);
                email = decodedToken?.email || getEmailFromQuery();
                if (email) {
                    setUserEmail(email);
                } else {
                    message.error("User information not found. Please log in again.");
                    history.push("/login");
                }
            } catch (error) {
                console.error("Token decode error:", error);
                history.push("/login");
            }
        } else {
            email = getEmailFromQuery();
            if (email) {
                setUserEmail(email);
            } else {
                message.error("Authentication required. Please log in again.");
                history.push("/login");
            }
        }
    }, [history, location.search]);

    useEffect(() => {
        const fetchUserObject = async () => {
            try {
                const token = sessionStorage.getItem("authToken");
                const { data } = await axios.post(
                    "/checkUser",
                    { email: userEmail },
                    { headers: { Authorization: token || "" } }
                );
                setUserObject(data);
            } catch (error) {
                console.error("Failed to fetch user object:", error);
            }
        };

        if (userEmail) {
            fetchUserObject();
        }
    }, [userEmail, history]);

    const generateAadharOtp = async () => {
        if (aadhar.length !== 12) {
            message.error("Please enter a valid 12-digit Aadhaar number.");
            return;
        }
        setLoading(true);
        try {
            const token = sessionStorage.getItem("authToken");
            if (!token) {
                message.error("Authentication required. Please log in again.");
                history.push("/login");
                return;
            }
    
            const headers: Record<string, string> = {
                "Content-Type": "application/json",
                ...(token && { Authorization: token }),
            };
    
            const response = await axios.post(
                "/generateAadharOtp", 
                { aadhar }, 
                { headers }
            );
    
            if (response.data.status) {
                message.success("OTP sent successfully to your Aadhaar-linked mobile.");
                setOtpSent(true);
                setRefid(response.data.refid);
            } else {
                message.error(response.data.message || "Failed to send OTP.");
            }
        } catch (error) {
            console.error("Error generating Aadhaar OTP:", error);
            message.error("An error occurred while sending OTP.");
        } finally {
            setLoading(false);
        }
    };
    
    const verifyAadharOtp = async () => {
        if (otp.length !== 6) {
            message.error("Please enter a valid 6-digit OTP.");
            return;
        }
        setLoading(true);
        try {
            const token = sessionStorage.getItem("authToken");
            if (!token) {
                message.error("Authentication required. Please log in again.");
                history.push("/login");
                return;
            }
            
            const headers: Record<string, string> = {
                "Content-Type": "application/json",
                ...(token && { Authorization: token }),
            };
            
            const response = await axios.post(
                "/verifyAadharOtp", 
                { ref_id: refid, otp }, 
                { headers }
            );
            
            if (response.data.status) {
                message.success("Aadhaar verified successfully!");
                setAadharVerified(true);
                setUserObject((prev) => ({ ...prev, aadhar }));
            } else {
                message.error(response.data.message || "Aadhaar verification failed.");
            }
        } catch (error) {
            console.error("Error verifying Aadhaar OTP:", error);
            message.error("An error occurred while verifying Aadhaar.");
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyUser = async () => {
        if (!userObject.displayName || !userObject.phone || !aadharVerified) {
            message.error("Please complete all verification steps before proceeding.");
            return;
        }
        setLoading(true);
        try {
            const token = sessionStorage.getItem("authToken");
            const headers: Record<string, string> = {
                "Content-Type": "application/json",
                ...(token && { Authorization: token }),
            };
            const response = await axios.post("/verifyUser", { userObject }, { headers });
            if (response.data.status) {
                message.success("Account verified successfully!");
                history.push("/");
            } else {
                message.error(response.data.message || "Verification failed.");
            }
        } catch (error) {
            console.error("Verification error:", error);
            message.error("An error occurred while verifying the account.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h2 className="text-2xl font-semibold">Account Verification Required</h2>
            {userEmail ? (
                <form onSubmit={(e) => e.preventDefault()} className="flex flex-col gap-3 mt-4">
                    <label className="font-semibold">Full Name:</label>
                    <input
                        type="text"
                        value={userObject.displayName}
                        onChange={(e) => setUserObject((prev) => ({ ...prev, displayName: e.target.value }))}
                        className="p-2 border rounded-md"
                        placeholder="Full Name"
                    />
                    <label className="font-semibold">Phone Number:</label>
                    <input
                        type="tel"
                        className="p-2 border rounded-md"
                        value={userObject.phone}
                        onChange={(e) => setUserObject((prev) => ({ ...prev, phone: e.target.value }))}
                        placeholder="Phone Number"
                    />
                    
                    {/* Aadhaar Verification */}
                    <label className="font-semibold">Aadhaar Number:</label>
                    <div className="flex gap-2">
                        <input
                            type="text"
                            className="p-2 border rounded-md"
                            value={aadhar}
                            onChange={(e) => setAadhar(e.target.value)}
                            placeholder="Enter 12-digit Aadhaar"
                        />
                        <button
                            className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-400"
                            onClick={generateAadharOtp}
                            disabled={loading || aadhar.length !== 12}
                        >
                            Generate OTP
                        </button>
                    </div>

                    {otpSent && (
                        <>
                            <label className="font-semibold">Enter OTP:</label>
                            <input
                                type="text"
                                className="p-2 border rounded-md"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                placeholder="Enter OTP"
                            />
                            <button
                                className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 disabled:bg-gray-400"
                                onClick={verifyAadharOtp}
                                disabled={loading || otp.length !== 6}
                            >
                                Verify Aadhaar
                            </button>
                        </>
                    )}

                    <button
                        className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:bg-gray-400"
                        onClick={handleVerifyUser}
                        disabled={loading || !aadharVerified}
                    >
                        {loading ? "Verifying..." : "Verify Account"}
                    </button>
                </form>
            ) : (
                <p>Loading user information...</p>
            )}
        </div>
    );
};

export default UserVerification;