import FooterNav from "components/FooterNav";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PrivacyPolicy from "containers/InfoPages/PrivacyPolicy";
import RefundPolicy from "containers/InfoPages/RefundPolicy";
import TermsConditions from "containers/InfoPages/TermsConditions";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import Page404 from "containers/Page404/Page404";
import PageAbout from "containers/PageAbout/PageAbout";
import PageContact from "containers/PageContact/PageContact";
import PageHome from "containers/PageHome/PageHome";
import PageLogin from "containers/PageLogin/PageLogin";
import PayPage from "containers/PayPage/PayPage";
import SiteHeader from "containers/SiteHeader";
import useWindowSize from "hooks/useWindowResize";
import React, { useEffect, useState, useContext } from "react";
import ReactPixel from "react-facebook-pixel";
import { BrowserRouter, Route, Switch, Redirect, useLocation } from "react-router-dom";
import Footer from "shared/Footer/Footer";
import ScrollToTop from "./ScrollToTop";
import FAQ from "containers/InfoPages/FAQ";
import { UserContext } from "contexts/UserContext";
import UserVerification from "containers/UserVerification/UserVerification";

// Define all routes
const pages = [
  { path: "/", exact: true, component: PageHome },
  { path: "/tc", component: TermsConditions },
  { path: "/pp", component: PrivacyPolicy },
  { path: "/rp", component: RefundPolicy },
  { path: "/listing/:id", component: ListingCarDetailPage },
  { path: "/listing", component: ListingCarPage },
  { path: "/checkout", component: CheckOutPage },
  { path: "/bookings/:id", component: PayPage },
  { path: "/account", component: AuthorPage },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/login", component: PageLogin },
  { path: "/faq", component: FAQ },
  { path: "/verify", component: UserVerification },
];

// Cookie Consent Component
const CookieConsent = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    if (!localStorage.getItem("cookieConsent")) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    ReactPixel.grantConsent();
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
    window.location.reload();
  };

  const declineCookies = () => {
    ReactPixel.revokeConsent();
    localStorage.setItem("cookieConsent", "false");
    setShowBanner(false);
  };

  if (!showBanner) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-900 text-white p-4 z-50">
      <div className="max-w-6xl mx-auto flex flex-col md:flex-row justify-between items-center gap-4">
        <p className="text-center md:text-left">
          We use cookies to improve your experience and analyze site usage.
          By continuing to browse, you agree to our use of cookies.
        </p>
        <div className="flex gap-4">
          <button onClick={acceptCookies} className="bg-blue-500 hover:bg-blue-600 px-6 py-2 rounded-lg">Accept</button>
          <button onClick={declineCookies} className="bg-gray-600 hover:bg-gray-700 px-6 py-2 rounded-lg">Decline</button>
        </div>
      </div>
    </div>
  );
};

// Meta Pixel Tracking Component
const MetaPixelTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    const pixelId = process.env.REACT_APP_META_PIXEL_ID;

    if (!pixelId) {
      console.warn("Meta Pixel ID is not defined.");
      return;
    }

    ReactPixel.init(pixelId);

    // Check consent status
    if (localStorage.getItem("cookieConsent") !== "true") {
      ReactPixel.revokeConsent();
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("cookieConsent") !== "true") return;

    ReactPixel.pageView();
    ReactPixel.trackCustom("PageEvent", {
      path: location.pathname,
      timestamp: new Date().toISOString(),
    });
  }, [location]);

  return null;
};

// Main Routes Component
const Routes = () => {
  const { user } = useContext(UserContext);
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;

  return (
    <BrowserRouter>
      <ScrollToTop />
      <MetaPixelTracker />
      <CookieConsent />
      <SiteHeader />

      <Switch>
        <Route path="/login" component={PageLogin} />
        <Route path="/" exact render={() => (user ? <PageHome /> : <Redirect to="/login" />)} />
        {pages.map(({ component: Component, path, exact }, ind) => (
          <Route key={ind} exact={exact} path={path} component={Component} />
        ))}
        <Route component={Page404} />
      </Switch>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;