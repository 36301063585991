import axios from "../../services/api";
import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React, { useEffect, useState } from "react";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}


const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2",
}) => {

  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    axios.get("/availability").then((res) => {
      console.log(res.data);
      setVehicles(res.data.data.vehicles.concat(res.data.data.nextAvailableVehicles));
    });

  },[])

  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      <Heading
        desc=""
        isCenter={headingCenter}
      >
        Available Vehicles
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {vehicles.map((vehicle, i) => (
          <CardComponentName key={i} vehicle={vehicle} />
        ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
