import { load } from '@cashfreepayments/cashfree-js';
import { message } from 'antd';
import Label from 'components/Label/Label';
import { useBookingData } from 'contexts/BookingContext';
import { UserContext } from 'contexts/UserContext';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import NcImage from 'shared/NcImage/NcImage';
import NcModal from 'shared/NcModal/NcModal';
import axios from '../../services/api';

const getDate = (startDate, startTime) => {

    var time = startTime.split(' ');
    var hour = time[0].split(':')[0];
    var min = time[0].split(':')[1];
    var ampm = time[1];
    if (ampm == 'PM' && parseInt(hour) != 12) {
        hour = (parseInt(hour) + 12).toString();
    }
    var date = startDate?.toDate().setHours(parseInt(hour), parseInt(min), 0, 0);
    return date;
};
const CheckOutPage = ({ className = '' }) => {
    const user = useContext(UserContext);
    const [mobileNo, setMobileNo] = useState('');
    useEffect(() => {
        if (!user.user) {
            history.push('/login');
        }
    }, [user.user]);
    const { booking, setBooking } = useBookingData();
    const [loading, setLoading] = useState(false);
    var history = useHistory();

    const calculateTotalAmount = () => {
        return (
            Math.round(booking.rentPrice)
        );
    };

    const calculateKmLimit = () => {
        return (
            (booking.outStation ? booking.vehicleData.KMLimitPerHourOutStation : booking.vehicleData.KMLimitPerHour || 5) * (Math.ceil(booking.rentDuration / 24) * 24)
        );
    };

    const submitFn = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!mobileNo || mobileNo.length < 10) {
            message.error('Please enter valid mobile number');
            setLoading(false);
            return;
        }

        const start = getDate(booking.dateRangeValue.startDate, booking.timeRangeValue?.startTime);
        const end = getDate(booking.dateRangeValue.endDate, booking.timeRangeValue.endTime);

        const token = await user.user?.getIdToken(true);

        axios
            .post(
                '/bookVehicle',
                {
                    startDate: start,
                    endDate: end,
                    vehicleId: booking.vehicleData.id,
                    actualPrice: calculateTotalAmount(),
                    amount: calculateTotalAmount(),
                    reserveAmount: 0,
                    discount: 0,
                    outStation: booking.outStation,
                    actualPickupDate: new Date(),
                    calculatedKmLimit: calculateKmLimit(),
                    calculatedHours: booking.rentDuration,
                    addons: [],
                    addonsAmount: 0,
                    status: 'pending',
                    mobile: mobileNo,
                    payments: [
                        {
                            paymentType: 'total',
                            amount: calculateTotalAmount(),
                            status: 'pending',
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            )
            .then((res) => {

                let checkoutOptions = {
                    paymentSessionId: res.data.data.payment_session_id,
                    redirectTarget: '_self',
                };
                load({
                    mode: process.env.REACT_APP_CASHFREE_ENV,
                }).then((cashfree) => {
                    cashfree.checkout(checkoutOptions);
                });
            })
            .catch((err) => {
                message.error({ content: err?.response?.data || 'Failed booking vehicle please ensure vehicle is available', key: 'checkout' });
                setLoading(false);
                console.log(err);
            });
    };
    if (Object.keys(booking).length <= 1) {
        history.push('/');
        message.error({
            content: 'Please select a vehicle first',
            key: 'checkout',
        });
    }

    const renderSidebar = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
                <div className="flex flex-col sm:flex-row sm:items-center">
                    <div className="flex-shrink-0 w-full sm:w-40">
                        <div className=" aspect-w-4 aspect-h-3 sm:aspect-h-4 rounded-2xl overflow-hidden">
                            <NcImage src={booking?.vehicleData?.images[0]} />
                        </div>
                    </div>
                    <div className="py-5 sm:px-5 space-y-3">
                        <div>
                            <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">{booking.vehicleData.vehicleName}</span>
                            <span className="text-base font-medium mt-1 block capitalize">{booking.vehicleData.type}</span>
                        </div>
                        <div className="w-10 border-b border-neutral-200  dark:border-neutral-700"></div>
                    </div>
                </div>
                <div className="flex flex-col space-y-4">
                    <h3 className="text-2xl font-semibold">Price detail</h3>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>
                            ₹ {(booking.outStation ? booking.vehicleData.outStationPrice : booking.vehicleData.price) * 24} x {Math.ceil(booking.rentDuration / 24)}{' '}
                            day(s)
                        </span>
                        <span>₹ {booking.rentPrice} /-</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>KM Limit</span>
                        <span>{calculateKmLimit()} km(s)</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Extra Charge Per KM</span>
                        <span>₹ {booking.vehicleData.extraKMCharges || 30} /-</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Addon charge</span>
                        <span>{`₹ ${booking?.addonsPrice ? booking?.addonsPrice : 0}`}</span>
                    </div>
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                        <span>Service charge</span>
                        <span>{`₹ ${booking?.vehicleData?.otherPrice ? booking?.vehicleData?.otherPrice : 0}`}</span>
                    </div>

                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                        <span>Total</span>
                        <span>{`₹ ${calculateTotalAmount()}`}</span>
                    </div>
                </div>
            </div>
        );
    };

    const renderMain = () => {
        return (
            <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">Confirm and payment</h2>
                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <div>
                    <div>
                        <h3 className="text-2xl font-semibold">
                            You're booking {booking.vehicleData.vehicleName} for {booking.outStation ? 'outstation' : 'instation'} purpose
                        </h3>
                        <div className="flex flex-col sm:flex-row sm:items-center my-2">
                            Also note that you need to carry your driving license and aadhar card for verification.
                        </div>
                        <NcModal
                            renderTrigger={(openModal) => (
                                <span onClick={() => openModal()} className="block lg:hidden underline  mt-1 cursor-pointer">
                                    View booking details
                                </span>
                            )}
                            renderContent={renderSidebar}
                            modalTitle="Booking details"
                        />
                    </div>
                    <div className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                        
                        <div className="p-4">
                            {
                                booking?.dateRangeValue?.startDate.format('MMM DD') +
                                    ' ' +
                                    booking?.timeRangeValue?.startTime +
                                    ' - ' +
                                    booking?.dateRangeValue?.endDate.format('MMM DD') +
                                    ' ' +
                                    booking?.timeRangeValue?.endTime
                            }
                        </div>
                    </div>
                </div>

                <div>

                    <div className="mt-0">
                        <div className="pt-0">
                            <form onSubmit={submitFn}>
                                <div className="space-y-1">
                                    <Label>Mobile Number</Label>
                                    <Input type="number" placeholder="Mobile Number" value={mobileNo} required onChange={(e) => setMobileNo(e.target.value)} />
                                </div>
                                <ButtonPrimary className="mt-4" loading={loading} type="submit">
                                    Confirm and pay ₹{' '}
                                    {
                                        calculateTotalAmount()
                                    }
                                </ButtonPrimary>
                                {
                                    <ButtonSecondary className="mt-4 ml-4" type="button" onClick={() => history.goBack()}>
                                        Back
                                    </ButtonSecondary>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
            <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
                <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
                <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
            </main>
        </div>
    );
};

export default CheckOutPage;
