import { Tab } from "@headlessui/react";
import { Spin, Alert } from "antd";
import { Link } from "react-router-dom";
import StayCard from "components/StayCard/StayCard";
import { UserContext } from "contexts/UserContext";
import { FC, Fragment, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Avatar from "shared/Avatar/Avatar";
import axios from "../../services/api";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";

export interface AuthorPageProps {
  className?: string;
}

const AuthorPage: FC<AuthorPageProps> = ({ className = "" }) => {
  const [categories] = useState(["Upcoming Bookings", "Completed Bookings"]);
  const user: any = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [listingData, setListingData] = useState<any>([]);
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const getListings = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = await user?.user?.getIdToken(true);
      const res = await axios.get("/user/bookings", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${token}`,
        },
      });
      setListingData(res.data.data || []);
    } catch (err: any) {
      setError("Failed to fetch bookings. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const checkVerified = async () => {
    try {
      const token = await user?.user?.getIdToken(true);
      const res = await axios.post(
        "/checkUser",
        { email: user?.user?.email },
        { headers: { Authorization: token } }
      );
      setVerified(res.data.verified || false);
    } catch (err) {
      console.error("Verification check failed", err);
    }
  };

  useEffect(() => {
    if (user?.user) {
      getListings();
      checkVerified();
    }
  }, [user]);

  const renderSidebar = () => (
    <div className="w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8 relative">
      {verified && (
        <div className="absolute top-4 right-4 flex items-center bg-green-100 text-green-800 px-2.5 py-1 rounded-full text-xs font-medium">
          <CheckBadgeIcon className="h-4 w-4 mr-1 text-green-600" /> Verified
        </div>
      )}
      <Avatar
        hasCheckedClass="w-6 h-6 -top-0.5 right-2"
        sizeClass="w-28 h-28"
        imgUrl={user?.user?.photoURL || "default-avatar.png"}
      />
      <div className="space-y-3 text-center flex flex-col items-center">
        <h2 className="text-3xl font-semibold">{user?.user?.displayName || "User"}</h2>
        {!verified && (
          <div className="mt-4 p-3 bg-yellow-50 border border-yellow-200 rounded-lg text-yellow-700">
            <p className="mb-2">Complete your verification to unlock exclusive offers!</p>
            <Link to="/verify" className="inline-block px-4 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 transition">
              Verify Profile
            </Link>
          </div>
        )}
      </div>
    </div>
  );

  const renderSection1 = () => (
    <div className="listingSection__wrap">
      <h2 className="text-2xl font-semibold">My listings</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      {error && <Alert message={error} type="error" showIcon className="mt-4" />}
      <Tab.Group>
        <Tab.List className="flex space-x-1 overflow-x-auto">
          {categories.map((item) => (
            <Tab key={item} as={Fragment}>
              {({ selected }) => (
                <button
                  className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:text-base sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                    selected
                      ? "bg-secondary-900 text-secondary-50"
                      : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                  } `}
                >
                  {item}
                </button>
              )}
            </Tab>
          ))}
        </Tab.List>
        {loading ? (
          <div className="flex justify-center items-center h-96">
            <Spin size="large" />
          </div>
        ) : (
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                {listingData.length > 0 ? (
                  listingData
                    .filter((val: any) => ["pending", "booked", "assigned", "ongoing"].includes(val.status))
                    .map((booking: any) => <StayCard key={booking._id} data={booking} />)
                ) : (
                  <p className="text-center text-neutral-500">No upcoming bookings found.</p>
                )}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-8 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-2">
                {listingData.length > 0 ? (
                  listingData
                    .filter((val: any) => ["completed", "failed", "cancelled"].includes(val.status))
                    .map((booking: any) => <StayCard key={booking._id} data={booking} />)
                ) : (
                  <p className="text-center text-neutral-500">No completed bookings found.</p>
                )}
              </div>
            </Tab.Panel>
          </Tab.Panels>
        )}
      </Tab.Group>
    </div>
  );

  return (
    <div className={`nc-AuthorPage ${className}`} data-nc-id="AuthorPage">
      <Helmet>
        <title>Profile || Booking React Template</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row">
        <div className="block flex-grow mb-24 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default AuthorPage;