import { message } from 'antd';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
import SectionHeroArchivePage from 'components/SectionHeroArchivePage/SectionHeroArchivePage';
import { defaultEndDate, defaultStartDate, useDateContext } from 'contexts/DateContext';
import heroRightImage from 'images/hero-right-car.png';
import React, { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import axios from '../../services/api';
import SectionGridFilterCard from './SectionGridFilterCard';

export interface ListingCarPageProps {
    className?: string;
}

export const useSearchDate = () => {
    const [startMs, setStartMs] = React.useState<number>(0);
    const [endMs, setEndMs] = React.useState<number>(0);
    const [bookingLocation, setBookingLocation] = React.useState<string>('Hyderabad, India');
    const { bookingLocation: bookingLocationContext, setBookingLocation: setBookingLocationContext } = useDateContext();
    const location = useLocation();
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const st = parseInt(searchParams.get('start') || defaultStartDate().toString());
        const en = parseInt(searchParams.get('end') || defaultEndDate().toString());
        const loc = searchParams.get('location') || 'Hyderabad, India';
        if (st !== startMs || en !== endMs || loc !== bookingLocation) {
            setStartMs(st);
            setEndMs(en);
            setBookingLocation(loc);
            setBookingLocationContext(loc);
        }
    });
    return [startMs, endMs, bookingLocation];
};

const ListingCarPage: FC<ListingCarPageProps> = ({ className = '' }) => {
    // useDateContext
    const { dateRangeValue,timeRangeValue } = useDateContext();
    // get start and end from url react
    const [vehicles, setVehicles] = React.useState<any[]>([]);

    const [startMs, endMs, loc]: any = useSearchDate();

    const searchApi = () => {
        if (endMs < startMs || new Date(startMs) < new Date()) {
            message.error('Please select a valid date range');
            return;
        }
        axios
            .get('/search', {
                params: {
                    start: startMs,
                    end: endMs,
                    location: loc === 'Hyderabad, India' ? '' : loc,
                },
            })
            .then((res) => {
                setVehicles(res.data.data);
                // scroll to top #vehicles-list
                const el = document.getElementById('vehicles-list');
                if (el) {
                    el.scrollIntoView({ behavior: 'smooth' });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {

        if (startMs && endMs) {
            searchApi();
        }
    }, [startMs, endMs, loc]);

    return (
        <div className={`nc-ListingCarPage relative overflow-hidden ${className}`} data-nc-id="ListingCarPage">
            <Helmet>
                <title>StartRides || Bike Rentals</title>
            </Helmet>
            <BgGlassmorphism />

            <div className="container relative">
                {/* SECTION HERO */}
                <SectionHeroArchivePage
                    rightImage={heroRightImage}
                    currentPage="Cars"
                    currentTab="Cars"
                    listingType={
                        <>
                            <i className="text-2xl las la-motorcycle"></i>
                            <span className="ml-2.5">{vehicles.length} vehicles</span>
                        </>
                    }
                    vehicles={vehicles}
                    className="pt-10 pb-24 lg:pb-28 lg:pt-16 "
                />

                {/* SECTION */}
                <SectionGridFilterCard className="pb-24 lg:pb-28" data={vehicles} />

            </div>
        </div>
    );
};

export default ListingCarPage;
