import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHero from "components/SectionHero/SectionHero";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <SectionHero className="pt-10 lg:pt-16 lg:pb-16" />

        {/* SECTION */}
        <SectionGridCategoryBox />
      </div>
    </div>
  );
}

export default PageHome;
