import CarCard from "components/CarCard/CarCard";
import Heading2 from "components/Heading/Heading2";
import { CarDataType } from "data/types";
import { FC } from "react";
import { useSearchDate } from "./ListingCarPage";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: CarDataType[];
}

const dateFormat: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  hour12: true,
};

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = [],
}) => {
  const [startMs, endMs] = useSearchDate();

  const formattedStartDate = startMs
    ? new Date(startMs).toLocaleString("en-IN", dateFormat).toUpperCase()
    : "N/A";
  const formattedEndDate = endMs
    ? new Date(endMs).toLocaleString("en-IN", dateFormat).toUpperCase()
    : "N/A";

  return (
    <div
      id="vehicles-list"
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Vehicles in Hyderabad"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            {data.length} vehicle(s)
            <span className="mx-2">·</span>
            <>
              From {formattedStartDate} - To {formattedEndDate}
            </>
          </span>
        }
      />

      <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        {data.length ? (
          data.map((car) => <CarCard key={car.id} data={car} />)
        ) : (
          <p className="text-neutral-500 dark:text-neutral-400">
            No vehicles available.
          </p>
        )}
      </div>
    </div>
  );
};

export default SectionGridFilterCard;